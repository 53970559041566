<template>
  <div>
    <div class="px-6">
      <v-container class="px-0">
        <div
          class="d-flex justify-space-between align-center flex-lg-row flex-row"
          :class="[
            $vuetify.breakpoint.lgAndDown ? 'mt-3 mb-3' : 'mt-7 mb-7',
            $vuetify.breakpoint.xsAndDown ? 'flex-column' : 'flex-row',
          ]"
        >
          <div
            class="w-100 d-flex align-center justify-space-between top-title"
          >
            <h1 class="font-weight-800">
              {{ $t("heading.navbar.tab.myHosting") }}
            </h1>
          </div>
        </div>
      </v-container>
    </div>

    <v-divider
      :class="$vuetify.breakpoint.lgAndDown ? 'mb-3' : 'mb-10'"
    ></v-divider>

    <div class="px-6">
      <v-container class="px-0">
        <v-row
          align="stretch"
          class="mx-0 flex-lg-row flex-column"
          no-gutters
          :class="[$vuetify.breakpoint.mobile ? 'mobile-container' : '']"
        >
          <hosting-pages-sidebar
            v-if="!serverAccountsLoading"
            :plans="serverAccounts"
            :activePlan="serverAccount"
            @update:activePlan="changeServerAccount"
          />
          <v-col
            cols="12"
            lg="10"
            :class="[$vuetify.breakpoint.lgAndDown ? `` : 'pl-12']"
          >
            <router-view
              :serverAccount="serverAccount"
              @pass-instance="passNewInstanceData"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Api from "../../apis/Api";

import HostingPagesSidebar from "../../components/HostingPagesSidebar.vue";

export default {
  components: {
    HostingPagesSidebar,
  },
  data() {
    return {
      serverAccountsLoading: true,
      serverAccounts: [],
      serverAccount: null,
      hostingAccountId: null,
    };
  },
  methods: {
    passNewInstanceData(instance) {
      this.hostingAccountId = instance.hostingId;
    },
    changeServerAccount: function (item) {
      this.serverAccount = item;
    },
    getHostingIdFromQuery: function () {
      if (!this.$route.query.id) {
        return false;
      }

      const id = this.$route.query.id;

      let query = Object.assign({}, this.$route.query);
      delete query.id;
      this.$router.replace({ query });
      return id;
    },
  },
  mounted() {
    if (!this.$store.state.home.user.has_hosting_access) {
      this.$router.push({ name: "Instances" });
      return;
    }

    const id = this.hostingAccountId
      ? this.hostingAccountId
      : this.getHostingIdFromQuery();

    Api.get("/server-accounts")
      .then((response) => {
        this.serverAccount = response.data.data[0];

        for (const record of response.data.data) {
          if (record.status != "active") {
            continue;
          }
          let hasAnyHostingPrivilege = false;
          for (const priv of record.privileges) {
            if (priv.startsWith("hosting.")) {
              hasAnyHostingPrivilege = true;
              break;
            }
          }
          if (!hasAnyHostingPrivilege) {
            continue;
          }
          record.name = `${record.plan_name} - ${record.domain}`;
          if (record.details && record.details.homedir) {
            record.homedir = `${record.details.homedir}/`;
          }

          if (record.details && record.details.mysql_db_prefix) {
            record.mysql_db_prefix = record.details.mysql_db_prefix;
          }
          if (record.details && record.details.nameservers) {
            record.nameservers = record.details.nameservers || [];
          }

          if (record.details && record.details.ip_address) {
            record.ip_address = record.details.ip_address || null;
          }

          record.getUserPrivileges = (privileges, permission) => {
            if (privileges && privileges.length) {
              return privileges.indexOf(permission) > -1;
            } else {
              return true;
            }
          };
          this.serverAccounts.push(record);
        }

        if (!id) {
          this.serverAccount = this.serverAccounts[0];
        } else {
          const foundPlan = this.serverAccounts.filter((plan) => {
            return plan.id == id;
          })[0];

          if (!foundPlan) {
            this.serverAccount = this.serverAccounts[0];
          } else {
            this.serverAccount = foundPlan;
          }
        }
      })
      .catch((error) => {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      })
      .finally(() => {
        this.serverAccountsLoading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.top-title {
  height: 46px;
}
::v-deep tr {
  transition: background-color 10s ease;
}
::v-deep tr.highlight {
  background-color: var(--v-primary-lighten2) !important;
}
</style>
